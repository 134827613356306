import React, { useState } from "react";
import "./Faucet.scss";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../assets/img/wisl-logo.png";

const Faucet = () => {
  const [wallet, setWallet] = useState("");
  const [loading, setLoading] = useState(false);

  const sendRequest = async () => {
    toast.loading("Requested...");
    setLoading(true);
    // await login();
    axios
      .post(`https://whale-islands-a8b32.hq.spicaengine.com/api/fn-execute/requestWisl`, {
        wallet,
      })
      .then((res) => {
        toast.dismiss();
        toast.success(res.data.message, {
          position: "top-center",
        });
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(error.response.data.message, {
          position: "top-center",
        });
      })
      .finally(() => {
        setWallet("");
        setLoading(false);
      });
  };

  const login = async () => {
    return axios.post(
      `https://whale-islands-a8b32.hq.spicaengine.com/api/fn-execute/login`,
      {
        identifier: wallet,
      }
    );
  };

  return (
    <div className="Faucet">
      <Toaster />
      <div className="Container">
        <div className="Top">
          <img className="Logo" src={logo} />
        </div>
        <div className="Middle">
          <h2>WISL Testnet Faucet</h2>
          <input
            value={wallet}
            placeholder="Wallet"
            className="Input CustomInput"
            onChange={(e) => setWallet(e.target.value)}
          ></input>
          <Button
            variant="contained"
            className={`SendBtn CustomBtn Disabled-${!wallet}`}
            onClick={() => sendRequest()}
            disabled={!wallet}
          >
            {loading ? (
              <CircularProgress size={30} color={"inherit"} />
            ) : (
              "Request 500 WISL"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Faucet;
