import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Faucet from "./pages/Faucet/Faucet";

const App = () => {
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    if (window.location.href.includes("faucet")) {
      setShowNavbar(false);
    }
  }, []);

  return (
    <BrowserRouter>
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/faucet" element={<Faucet />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
