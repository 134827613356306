import "./Features.scss";
import React, { useState, useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import FeaturesIcon from "../../assets/img/game.png";
import ExploreImg from "../../assets/img/f_explore.png";
import ExportImg from "../../assets/img/f_export.png";
import ImproveImg from "../../assets/img/f_improve.png";
import BuildImg from "../../assets/img/game.png";

const features = [
  {
    img: BuildImg,
    title: "Build",
    description:
      " Share the information with others and build your business network in WhaleIslands.",
  },
  {
    img: ImproveImg,
    title: "Improve",
    description: "Improve your crew and increase the business efficiency.",
  },
  {
    img: ExportImg,
    title: "Export",
    description: "Export the goods to other cities and earn $WISL.",
  },
  {
    img: ExploreImg,
    title: "Explore",
    description:
      "Explore new lands, products and be the first exporter to be a whale in Whale Islands.",
  },
];

const Features = () => {
  return (
    <div id="features" className={`Features ComponentSection`}>
      <div className="SectionTitle">
        <img src={FeaturesIcon} className="Icon" />
        <h1>Features</h1>
      </div>
      <div className="Container">
        {features.map((item, index) => (
          <div
            className={`FeatureBox`} 
            key={index}
          >
            <div className="Feature">
              <div className="FeatureImg">
                <img src={item.img} />
              </div>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
