import React, { useState, useCallback, useEffect } from "react";
import "./Rounds.scss";
import { useInView } from "react-intersection-observer";
import Button from "@mui/material/Button";
import { HashLink } from "react-router-hash-link";
import RoundIcon from "../../assets/img/round.png";

const roundsData = [
  {
    title: "Private Rounds",
    price: "?M", // 1.1
    distribution: "8",
    tokenAmount: "8.000.000",
    vesting:
      "6 months, 40% on TGE,  followed by the monthly release",
  },
  {
    title: "Public Rounds",
    price: "?M", // 3.08
    distribution: "15",
    tokenAmount: "15.000.000",
    vesting:
      "6 months, 40% on TGE, followed by the monthly release",
  },
];

const Rounds = () => {
  const [positionY, setPositionY] = useState(window.scrollY);
  const [scaleValue, setScaleValue] = useState(3.5);
  const [isShowed, setIsShowed] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const handleNavigation = useCallback(
    (e) => {
      const currentTarget = e.currentTarget;
      setPositionY(currentTarget.scrollY);

      if (currentTarget.scrollY > 4450 && currentTarget.scrollY < 6900) {
        setIsShowed(true);
        setScaleValue(Math.max((6900 - currentTarget.scrollY) / 1000, 1));
      } else if (isShowed && currentTarget.scrollY <  4450) {
        setIsShowed(false);
        setScaleValue(3.5);
      } else if (isShowed && currentTarget.scrollY > 6900) {
        setIsShowed(false);
        setScaleValue(1);
      }
    },
    [positionY]
  );
  useEffect(() => {
    setPositionY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div
      id="rounds"
      className={`Rounds ComponentSection visible_${inView} RoundsAnimation-${isShowed}`}
    >
      <div className="SectionTitle">
        <img src={RoundIcon} className="Icon" />
        <h1>Rounds</h1>
      </div>
      <div className="Container" style={{opacity: (1 - ((scaleValue - 1) / 1.5))}}>
        <img
          className="ComponentBg"
          src="https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61d7f60e1e7c2f002d4bdb63?alt=media&timestamp=1641543183240"
          style={{ transform: `scale(${scaleValue})`}}
        />
        <div ref={ref} className="CardsContainer">
          {roundsData.map((item, index) => (
            <div className="Card" key={index}>
              <h3>{item.title}</h3>
              <div className="Content">
                <h1>${item.price}</h1>
                <p>Distribution {item.distribution}%</p>
                <p>Token Amount {item.tokenAmount}</p>
                <p>{item.vesting}</p>
              </div>
              <HashLink className="RegisterBtnLink" to="#allocation" smooth>
                <Button
                  disabled={index != 0}
                  variant="contained"
                  className="RegisterBtn CustomBtn"
                >
                  Register
                </Button>
              </HashLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rounds;
