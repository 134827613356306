import "./Navbar.scss";
import React, { useState, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { HashLink } from "react-router-hash-link";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/img/wisl-logo.png";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenSideMenu, setOpenSideMenu] = useState(false);
  const [positionY, setPositionY] = useState(window.scrollY);
  const [logoAnimation, setLogoAnimation] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const handleNavigation = useCallback(
    (e) => {
      if (e.currentTarget.scrollY > 1 && !logoAnimation) {
        setLogoAnimation(true);
      }
      if (e.currentTarget.scrollY < 1) {
        setLogoAnimation(false);
      }

      if (isOpenSideMenu) {
        setOpenSideMenu(false);

        const currentTarget = e.currentTarget;
        setPositionY(currentTarget.scrollY);
      }
    },
    [positionY]
  );
  useEffect(() => {
    setPositionY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation, isOpenSideMenu]);

  const navItems = [
    {
      id: "about",
      title: "About",
    },
    {
      id: "game",
      title: "Game",
    },
    {
      id: "rounds",
      title: "Rounds",
    },
    {
      id: "roadmap",
      title: "Roadmap",
    },
    // {
    //   id: "tokenomics",
    //   title: "Tokenomics",
    // },
    {
      id: "playtoearn",
      title: "Play & Earn",
    },
    // {
    //   id: "team",
    //   title: "Our Team"
    // }
  ];

  const renderNavBarItems = () => {
    return navItems.map((item, index) => (
      <HashLink className="NavbarItem" to={`#${item.id}`} smooth key={index}>
        {item.title}
      </HashLink>
    ));
  };

  return (
    <nav className="Navbar">
      <div className="Container">
        <div className={`NavbarMenu ${isOpen && "IsActive"}`}>
          <img className={`Logo LogoAnimation-${logoAnimation}`} src={logo} />
          {!isTabletOrMobile && (
            <div>
              {renderNavBarItems()}{" "}
              <a
                className="NavbarItem"
                href="https://docs.whaleislands.com/"
                target="_blank"
              >
                Whitepaper
              </a>
            </div>
          )}
          {isOpenSideMenu ? (
            <CloseIcon className="MenuIcon" onClick={() => setOpenSideMenu(false)} />
          ) : (
            <MenuIcon className="MenuIcon" onClick={() => setOpenSideMenu(true)} />
          )}
          <a
            className="NavbarItem LaunchBtn"
            //href="https://game.whaleislands.com/"
            target="_blank"
          >
            Launch (Soon)
          </a>
        </div>
        <div className={`SideMenu ${isOpenSideMenu && "IsActive"}`}>
          {renderNavBarItems()}
          <a className="NavbarItem" href="https://docs.whaleislands.com/" target="_blank">
            Whitepaper
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
