import React, { useState } from "react";
import "./Footer.scss";
import { HashLink } from "react-router-hash-link";
import Button from "@mui/material/Button";

import logo from "../../assets/img/wisl-logo.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import discord from "../../assets/img/discord_white.svg";
import avalanche from "../../assets/img/avalanche_white.png";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Content">
        <div className="Left">
          <div className="Logo">
            <img src={logo} />
          </div>
          <div className="SocialNetworks">
            <a href="https://twitter.com/wislapp" target="_blank">
              <div className="Social button">
                <TwitterIcon></TwitterIcon>
              </div>
            </a>
            <a href="https://t.me/WhaleIslands" target="_blank">
              <div className="Social button">
                <TelegramIcon></TelegramIcon>
              </div>
            </a>
            <a href="https://discord.gg/APtrFfSF7u" target="_blank">
              <div className="Social button">
                <img src={discord} alt="Discord"></img>
              </div>
            </a>
          </div>
        </div>
        <div className="NavButtons">
          <div className="Middle">
            <HashLink className="FooterItem" to="#about" smooth>
              About
            </HashLink>

            <HashLink className="FooterItem" to="#playtoearn" smooth>
              Play & Earn
            </HashLink>
            <HashLink className="FooterItem" to="#" smooth>
              Marketplace
            </HashLink>

            <HashLink className="FooterItem" to="#" smooth>
              News
            </HashLink>
          </div>
          <div className="Middle">
            <a className="FooterItem">Terms of Use</a>

            <a className="FooterItem">Privacy Policy</a>
            <a className="FooterItem">FAQ</a>

            <a
              className="FooterItem"
              href="https://docs.whaleislands.com/"
              target="_blank"
            >
              Whitepaper
            </a>
          </div>
        </div>
        <div className="AvalancheLogo">
          <img src={avalanche} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
