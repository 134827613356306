import "./About.scss";
import React from "react";
import { useInView } from "react-intersection-observer";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import discord from "../../assets/img/discord_white.svg";

const About = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className={`About visible_${inView}`} id="about">
      <div className="Content">
        <div className="Container">
          <h1>Whale Islands, The First Decentralized Resource Management</h1>
          <h3>Trade in game, earn in real.</h3>
          <div className="SocialNetworks">
            <a href="https://twitter.com/wislapp" target="_blank">
              <div className="Social">
                <TwitterIcon></TwitterIcon>
              </div>
            </a>
            <a href="https://t.me/WhaleIslands" target="_blank">
              <div className="Social">
                <TelegramIcon></TelegramIcon>
              </div>
            </a>
            <a href="https://discord.gg/APtrFfSF7u" target="_blank">
              <div className="Social">
                <img src={discord} alt="Discord"></img>
              </div>
            </a>
          </div>
        </div>
        <div className="VideoContainer">
          <video muted loop className="IntroVideo" width="337" height="600" autoPlay >
            <source src="https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61e7cc94300d83002d30826b?alt=media&timestamp=1642581141285" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default About;
