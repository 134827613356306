import About from "../components/About/About";
import Allocation from "../components/Allocation/Allocation";
import Features from "../components/Features/Features";
import Footer from "../components/Footer/Footer";
import Game from "../components/Game/Game";
import PlayToEarn from "../components/PlayToEarn/PlayToEarn";
import Rounds from "../components/Rounds/Rounds";
import Roadmap from "../components/Roadmap/Roadmap";
import Team from "../components/Team/Team";
import Tokenomics from "../components/Tokenomics/Tokenomics";

const Home = () => {
  return (
    <main>
      <About></About>
      <Features></Features>
      <Game></Game>
      <Allocation></Allocation>
      <Rounds></Rounds>
      <Roadmap></Roadmap>
      {/* <Tokenomics></Tokenomics> */}
      {/* <Team></Team> */}
      <PlayToEarn></PlayToEarn>
      <Footer></Footer>
    </main>
  );
};

export default Home;
