import React from "react";
import "./PlayToEarn.scss";
import ship from "../../assets/img/ship.png";
import { useInView } from "react-intersection-observer";
import PlayAndEarnIcon from "../../assets/img/playandearn.png";


const PlayToEarn = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div id="playtoearn" ref={ref} className={`PlayToEarn ComponentSection visible_${inView}`}>
      <div className="SectionTitle">
        <img src={PlayAndEarnIcon} className="Icon" />
        <h1>Play & Earn</h1>
      </div>
      <div className="Content">
        <div className="Text">
          <h3>
            Take a ship, set sail for Whale Islands and grow your business. We make
            trading casual, fun and profitable for every one.
          </h3>
        </div>
        <div className="ImgBox"><img src={ship} /></div>
      </div>
    </div>
  );
};

export default PlayToEarn;
