import "./Roadmap.scss";
import React, { useState, useEffect, useCallback } from "react";
import RoadMapIcon from "../../assets/img/roadmap.png";
import Hammer from "../../assets/img/hammer.png";
import { useMediaQuery } from "react-responsive";

const planArr = [
  {
    title: "Q1 2022 plans",
    items: [
      "Game Mechanics: Game Economics",
      "Game Mechanics: Ship Management",
      "Game Mechanics: Instant Events (first phase)",
      "Game Mechanics: Cities & Countries",
      "Game Mechanics: NFT items",
      "Website Development",
      "Testnet Deployment",
    ],
  },
  {
    title: "Q2 2022 plans",
    items: [
      "Private Sale Round",
      "Initial NFT Offering",
      "Game Mechanics: Companies",
      "Game Mechanics: Merchants & Newspapers",
      "Game Mechanics: Lending & Borrowing (Marketplace)",
      "Game Mechanics: Instant Events (second phase)",
      "ICO on Launchpad",
      "Mainnet Deployment",
    ],
  },
  {
    title: "Q3 2022 plans",
    items: [
      "Game mechanics: Wars & Embargos",
      "New NFT Items",
      "Improving The Game World (New Cities & Countries)",
      "New Ships",
      "UI & UX Improvements",
    ],
  },
  {
    title: "Q4 2022 plans",
    items: ["Attacking To Another Ship", "Listings"],
  },
];

const Roadmap = () => {
  const [positionY, setPositionY] = useState(window.scrollY);
  const [isShowed, setIsShowed] = useState(false);
  const [leftPos, setLeftPos] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const handleNavigation = useCallback(
    (e) => {
      const currentTarget = e.currentTarget;
      setPositionY(currentTarget.scrollY);

      if (currentTarget.scrollY > 6350 && currentTarget.scrollY < 9050) {
        setIsShowed(true);
        setLeftPos((120 * Math.min(currentTarget.scrollY - 7000, 2000)) / 2000);
      } else if (isShowed && currentTarget.scrollY > 9050) {
        setLeftPos(120);
        setIsShowed(false);
      } else if (isShowed && currentTarget.scrollY < 6350) {
        setLeftPos(-20);
        setIsShowed(false);
      }
    },
    [positionY]
  );
  useEffect(() => {
    setPositionY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div id="roadmap" className={`Roadmap ComponentSection RoadmapAnimation-${isShowed}`}>
      <div className="SectionTitle">
        <img src={RoadMapIcon} className="Icon" />
        <h1>Roadmap</h1>
      </div>
      <div className="Content">
        <div className="RoadMapContainer">
          <div
            className={`HammerContainer`}
            style={{ left: `${leftPos + 5}%`, opacity: (100 - leftPos) / 100 }}
          >
            <img src={Hammer} className="Hammer" />
          </div>
          <div className="PlanContainer">
            <div className="Line"></div>
            <div className="DotsContainer">
              {planArr.map((plan, index) => (
                <div className={`Dot Dot-${index + 1}`} key={index}></div>
              ))}
            </div>
            <div className="Plan Top">
              {!isMobile && (
                <div className="Left">
                  <h3>Q2 2022 plans</h3>
                  {planArr[1].items.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </div>
              )}
              <div className="Right">
                <h3>Q4 2022 plans</h3>
                {planArr[3].items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </div>
            </div>
            <div className="Plan Bottom">
              <div className="Left">
                <h3>Q1 2022 plans</h3>
                {planArr[0].items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </div>
              {isMobile && (
                <div className="Left">
                  <h3>Q2 2022 plans</h3>
                  {planArr[1].items.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </div>
              )}
              <div className="Right">
                <h3>Q3 2022 plans</h3>
                {planArr[2].items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="FadeContainer" style={{ left: `${leftPos}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
