import React, { useState } from "react";
import "./Allocation.scss";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import NewsletterIcon from "../../assets/img/newsletter.png";
import GlowImg from "../../assets/img/glow.png";
import Countries from "../../assets/countries.json";

const Allocation = () => {
  const [email, setEmail] = useState<string>("");
  const [reqAllocation, setReqAllocation] = useState<any>(null);
  const [totalReqAllocation, setTotalReqAllocation] = useState<number>(0);
  const [salePer, setSalePer] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [entity, setEntity] = useState("select_entity");
  const [country, setCountry] = useState("select_country");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    getTotalReqAllocation();
  }, []);

  useEffect(() => {
    handleFormChange();
  }, [email, reqAllocation, entity, country]);

  const getTotalReqAllocation = () => {
    axios
      .get(
        `https://whale-islands-a8b32.hq.spicaengine.com/api/fn-execute/calculateReqAllocation`
      )
      .then((res: any) => {
        setSalePer((res.data["totalRequestedAllocation"] * 100) / 50);
        setTotalReqAllocation(res.data["totalRequestedAllocation"] || 0);
      });
  };

  const sendRequest = () => {
    let data = {
      email: email,
      req_allocation: parseInt(reqAllocation),
      entity: entity,
      country: country,
      discord: discord,
      twitter: twitter
    };
    setLoading(true);
    axios
      .post(
        `https://whale-islands-a8b32.hq.spicaengine.com/api/bucket/61d6e1617b6080002dc77276/data`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "APIKEY 1m50619ky35ev1o",
          },
        }
      )
      .then(() => {
        setEmail("");
        setEntity("select_entity");
        setCountry("select_country");
        setTwitter("");
        setDiscord("");
        setReqAllocation(null);
        getTotalReqAllocation();
        setRegistered(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormChange = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      reg.test(email) === true &&
      reqAllocation >= 2000 &&
      reqAllocation <= 50000 &&
      entity != "select_entity" &&
      country != "select_country"
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  return (
    <div
      ref={ref}
      id="allocation"
      className={`Allocation ComponentSection visible_${inView}`}
    >
      <div className="SectionTitle">
        <img src={NewsletterIcon} className="Icon" />
        <h1>Whitelist</h1>
      </div>
      <div className="Container">
        {registered ? (
          <div className="Registered">
            We saved your allocation. <br />
            <br />
            To change your allocation, you can send another form. <br />
            <br />
            Sending more than 2 form may result elimination from whitelist.
          </div>
        ) : (
          <div className="Form">
            <select
              value={entity}
              className="Input CustomInput"
              onChange={(e) => setEntity(e.target.value)}
            >
              <option value="select_entity">Entity</option>
              <option value="individual">Individual</option>
              <option value="registered_vc">Registered VC</option>
              <option value="group_investers">A group of Investers</option>
            </select>
            <input
              value={email}
              placeholder="Email"
              type="email"
              className="Input CustomInput"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <input
              value={twitter}
              placeholder="Twitter Handle"
              type="email"
              className="Input CustomInput"
              onChange={(e) => setTwitter(e.target.value)}
            ></input>
            <input
              value={discord}
              placeholder="@discordname#ID"
              type="email"
              className="Input CustomInput"
              onChange={(e) => setDiscord(e.target.value)}
            ></input>
            <input
              value={reqAllocation == null ? "" : reqAllocation}
              type="number"
              placeholder="Requested Allocation"
              className="Input CustomInput"
              onChange={(e) => {
                if (Number(e.target.value) > 50000) {
                  setReqAllocation(50000);
                } else {
                  setReqAllocation(e.target.value);
                }
              }}
            ></input>
            <select
              className="Input CustomInput"
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="Input CustomInput" value="select_country">
                Country
              </option>
              {Countries.map((item, index) => (
                <option className="Input CustomInput" value={item.code} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <p className="WarningMessage">
              *The requested allocation must be between $2000 and $50,000
            </p>
            <Button
              variant="contained"
              endIcon={!loading && <SendIcon />}
              className={`SendBtn CustomBtn Disabled-${!formIsValid}`}
              onClick={() => sendRequest()}
              disabled={!formIsValid}
            >
              {loading ? <CircularProgress size={30} color={"inherit"} /> : "Request"}
            </Button>
          </div>
        )}

        <div className="Card">
          <img src={GlowImg} className="GlowEffect" />
          <div className="Top">
            <div className="Text">
              <span>Whale Islands</span>
              <h3>WISL</h3>
            </div>
          </div>
          <div className="Bottom">
            <div>
              <span>Total Raising</span>
              <h3>$?</h3>
            </div>
            <div className="Right">
              <span>Token Distribution</span>
              <h3>50M</h3>
            </div>
          </div>
          <div className="Middle">
            <div className="Round">
              <div className="Left">
                <span>Price</span>
                <h3>$?</h3>
              </div>
              <div className="Right">
                <span>Time left</span>
                <h3>TBD</h3>
              </div>
            </div>
            <div className="ProgressBar">
              <div className="BarContainer">
                {/* <div className="Bar" style={{ width: `${salePer}%` }}> */}
                <div className="Bar" style={{ width: `0%` }}>
                  {/* <span>Sale: {salePer.toFixed(2)}%</span> */}
                  <span>Sale: 0%</span>
                </div>
              </div>
              <div className="ProgressSpan">
                <span></span>
                {/* <span>{totalReqAllocation.toFixed(2)}/50M</span> */}
                <span>0/50M</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Allocation;
